import request from '@/utils/http';
import utils from '@/utils/common';
const apiCrmHost = 'api-lcrm';

// 列表查询-续签登记表
export const contractRenewalRegistrationPage = (data) => {
    return request({
        url: apiCrmHost + '/contract/renewal/registration/page',
        method: 'post',
        data
    });
};
// 列表查询-续签登记待办列表
export const queryContractRenewalByUserCode = (data) => {
    return request({
        url: apiCrmHost + '/queryContractRenewalByUserCode',
        method: 'post',
        data
    });
};
// 保存-续签表、续签待办表保存（批量）
export const contractRenewalRegistrationSave = (data) => {
    return request({
        url: apiCrmHost + '/contract/renewal/registration/save',
        method: 'post',
        data
    });
};
// 完成待办
export const setHaveDoneApi = (data) => {
    return request({
        url: apiCrmHost + '/setHaveDone',
        method: 'post',
        data
    });
};
// 续签登记导入
export const registrationImportApi = (params) => {
    return request({
        url: `/${apiCrmHost}/contract/renewal/registration/import`,
        method: 'post',
        data: params
    }).then(res => res);
};
// 下载模板
export const renewalFileDownloadApi = (params = {}) => {
    return request({
        url: `/${apiCrmHost}/contract/renewal/file/download`,
        method: 'get',
        responseType: 'blob',
        params
    }).then(res => {
        utils.downloadStream(res, params.fileName);
    });
};
// 续签登记详情
export const registrationGetByIdApi = (params) => {
    return request({
        url: `/${apiCrmHost}/contract/renewal/registration/getById`,
        method: 'get',
        params
    }).then(res => res);
};
// 续签登记新增-zxh
export const contractRenewalRegistrationInsert = (data) => {
    return request({
        url: apiCrmHost + '/contract/renewal/registration/addBatch',
        method: 'post',
        data
    });
};
// 续签登记删除-zxh
export const contractRenewalRegistrationDel = (data) => {
    return request({
        url: apiCrmHost + '/contract/renewal/registration/remove',
        method: 'DELETE',
        data
    });
};
