<template>
    <div class="add-file-dialog">
        <el-dialog
            :visible="true"
            width="700px"
            :title="fileReadonly ? '附件详情' : '附件管理'"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            <el-form ref="ruleForm" :model="form" label-width="0" :rules="rules">
                <el-form-item prop="conditionsDetail">
                    <!-- <el-input
                        v-model="form.conditionsDetail"
                        :maxlength="4000"
                        type="textarea"
                        :rows="5"
                        :show-word-limit="true"
                        :clearable="true"></el-input> -->
                        <lots-upload-button :text="'上传附件'" :bucket="'annto-lcrm'" :disabled="fileReadonly"
                            @callback="(data) => { upLoadData(form, data); }">
                        </lots-upload-button>
                        <div class="file-infos">
                            <template v-if="form.file
                                && form.file.length">
                                <div
                                    class="file-list"
                                    v-for="(item, index) in form.file"
                                    :key="index + item.fileName">
                                    <div
                                        class="file-name"
                                        :title="item.fileName">
                                        {{`${index+1}. `}}
                                        <a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a>
                                    </div>
                                    <div class="delete-btn">
                                        <el-button
                                            type="text"
                                            plain>
                                            <a :href="item.fileUrl" target="_blank">预览</a>
                                        </el-button>
                                        <el-button
                                            type="text"
                                            plain>
                                            <a :href="item.fileUrl" target="_blank">下载</a>
                                        </el-button>
                                        <el-button
                                            type="text"
                                            :disabled="fileReadonly"
                                            plain
                                            @click="handleDeleteFile(form, index)">删除</el-button>
                                    </div>
                                </div>
                            </template>
                        </div>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <lots-button size="mini" @click="emit('handleClose', false)">取消</lots-button>
                <lots-button type="primary" size="mini" @click="confirm">确认</lots-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, onMounted } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import lotsUploadButton from '@/components/lots/oss/UploadBtn';
export default {
    components: {
        lotsButton,
        lotsUploadButton
    },
    props: {
        fileReadonly: {
            type: Boolean,
            default: false
        },
        row: {
            type: Object,
            default: () => { return {}; }
        }
    },
    setup(props, setupContext) {
        const { emit } = setupContext;
        const rules = {
            // file: [{ required: true, message: '附件不能为空', trigger: 'change' }]
        };
        const form = reactive({
            file: []
        });
        onMounted(() => {
            form.file = props.row.file || [];
        });
        const upLoadData = (form, data) => {
            if (data && +data.code === 0) {
                form['file'].push({ fileName: data.data.name, fileUrl: data.data.downUrl });
            }
        };
        const handleDeleteFile = (form, index) => {
            form['file'].splice(index, 1);
        };
        const confirm = () => {
            setupContext.refs.ruleForm.validate((flag) => {
                if (flag) {
                    emit('confirm', form.file);
                }
            });
        };
        return {
            emit,
            rules,
            form,
            confirm,
            upLoadData,
            handleDeleteFile
        };
    }
};
</script>
<style lang="less">
.add-file-dialog {
    .file-infos {
        width: 100%;
        padding: 0 10px 0 0;
        .file-list {
            width: 100%;
            padding: 2px 0 2px 4px;
            display: flex;
            // justify-content: space-between;
            .file-name {
                // flex: 1;
                width: 600px;
                padding-right: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
            .view-btn, .delete-btn {
                width: 200px;
                display: flex;
                a {
                    text-decoration: none;
                    color: #4285F5;
                }
                .el-button {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
